import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { gql } from "graphql-request";
import Link from "next/link";
import React, { useMemo, useRef, useState } from "react";

import useElementOffset from "@/hooks/useElementOffset";
import useLocalStorageState from "@/hooks/useLocalStorageState";

import type { PropsWithChildren } from "react";

import type { SituationType, TimelineSituationFragment } from "@/lib/graphql";
import getMinMaxDates from "@/lib/getMinMaxDates";
import { getDateString } from "./NotificationAffectedTimeIntervalRange";

type Unit = "day" | "hour" | "week";

const unitWidth = 40;

const inMs = { day: 86400000, hour: 3600000, week: 604800000 };

function toTime(input: number | string) {
  return new Date(input).getTime();
}

function getFirstVisibleFromTime(situation: TimelineSituationFragment) {
  return Math.max(
    ...situation.publicationWindows.map((p) => {
      return toTime(p.startTime);
    }),
  );
}

function getTimeDiff(unit: Unit, start: number, end: number) {
  const s = new Date(start);
  const e = new Date(end);

  if (unit === "hour") {
    return Math.ceil((e.getTime() - s.getTime()) / (1000 * 3600 * 24));
  } else if (unit === "week") {
    return e.getFullYear() - s.getFullYear();
  }
  return e.getMonth() - s.getMonth() + 12 * (e.getFullYear() - s.getFullYear());
}

function getISODate(input?: number | string) {
  const date = input ? new Date(input) : new Date();
  return date.toISOString().substring(0, 10);
}

function getISODateTime(input?: number | string) {
  const date = input ? new Date(input) : new Date();
  return date.toISOString().substring(0, 16);
}

function getFirstLocaleDateString(
  items: Record<string, string>[],
  field: string,
) {
  const first = Math.min(
    ...items.map((i) => {
      return new Date(i[field]).getTime();
    }),
  );
  return Number.isFinite(first) && new Date(first).toLocaleDateString("de");
}

function getLastLocaleDateString(
  items: Record<string, string>[],
  field: string,
) {
  const last = Math.max(
    ...items.map((i) => {
      return new Date(i[field]).getTime();
    }),
  );
  return Number.isFinite(last) && new Date(last).toLocaleDateString("de");
}

function getCalendarWeek(date: Date) {
  const j1 = new Date(date.getFullYear(), 0, 1);
  const time = date.getTime();
  return Math.ceil(((time - j1.getTime()) / 86400000 + j1.getDay() + 1) / 7);
}

function UnitButton({
  children,
  isActive,
  onClick,
}: PropsWithChildren<{
  isActive: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}>) {
  return (
    <button
      className={`rounded px-3 py-2 text-sm text-slate-600 ${
        isActive ? "bg-white font-bold" : "font-medium hover:bg-slate-100"
      }`}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
}

function ToolButton({
  children,
  className = "",
  onClick,
}: PropsWithChildren<{
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}>) {
  return (
    <button
      className={`flex rounded-xs bg-slate-100 p-2 text-sm font-medium text-slate-600 hover:bg-white ${className}`}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
}

function getUnitFloor(input: number) {
  return Math.floor(input / unitWidth) * unitWidth;
}

function getUnitCeil(input: number) {
  return Math.ceil(input / unitWidth) * unitWidth;
}

function Bar({
  fraction,
  onClickSituation,
  row,
  situation,
  start,
  width,
}: {
  fraction: number;
  onClickSituation: (situation: TimelineSituationFragment) => void;
  row: number;
  situation: TimelineSituationFragment;
  start: number;
  width: number;
}) {
  const textRef = useRef<SVGTextElement>(null);
  const [tipWidth, setTipWidth] = useState(0);
  const yRow = row * 36;
  const ty1 = 30 + yRow;
  const ty2 = 40 + yRow;
  let nMin = Infinity;
  let nMax = -Infinity;
  const atis = situation.affectedTimeIntervals.map((ati) => {
    const atiStart = getUnitFloor((toTime(ati.startTime) - start) / fraction);
    const atiEnd = getUnitCeil((toTime(ati.endTime) - start) / fraction);
    nMin = Math.min(atiStart, nMin);
    nMax = Math.max(atiEnd, nMax);
    return (
      <rect
        className="animate-[appear_1s] fill-orange-300 transition-[x,y] duration-300"
        data-cy="situations-timeline-bar-ati"
        height="24"
        key={ati.id}
        rx="4"
        width={atiEnd - atiStart - 4}
        x={atiStart + 2}
        y={10 + yRow}
      />
    );
  });
  const publications = situation.publicationWindows.map((p) => {
    const pStart = getUnitFloor((toTime(p.startTime) - start) / fraction);
    const pEnd = getUnitCeil((toTime(p.endTime) - start) / fraction);
    nMin = Math.min(pStart, nMin);
    nMax = Math.max(pEnd, nMax);
    return (
      <rect
        className="fill-blue animate-[appear_1s] transition-[x,y] duration-300"
        data-cy="situations-timeline-bar-publication"
        height="28"
        key={p.id}
        rx="6"
        width={pEnd - pStart}
        x={pStart}
        y={8 + yRow}
      />
    );
  });
  const xTip =
    Math.max(0, nMin) + (Math.min(width, nMax) - Math.max(0, nMin)) / 2;

  return (
    <g
      className="group hover:cursor-zoom-in"
      data-cy="situations-timeline-bar"
      key={situation.id}
      onClick={() => {
        return onClickSituation(situation);
      }}
      onMouseEnter={() => {
        return textRef.current && setTipWidth(textRef.current.getBBox().width);
      }}
    >
      <g className="hidden opacity-0 transition-opacity duration-300 group-hover:block group-hover:opacity-100">
        <rect
          className="fill-blue-300"
          height="34"
          rx="9"
          width={nMax - nMin + 6}
          x={nMin - 3}
          y={5 + yRow}
        />
      </g>
      {publications}
      {atis}
      <g className="hidden opacity-0 transition-opacity duration-300 group-hover:block group-hover:opacity-100">
        <polygon
          className="fill-slate-600"
          points={`${xTip + 2} ${ty1}, ${xTip - 6} ${ty2}, ${xTip + 10} ${ty2}`}
        />
        <rect
          className="fill-slate-600"
          height="22"
          rx="4"
          width={tipWidth + 16 || 0}
          x={xTip - 16}
          y={38 + yRow}
        />
        <text
          className="fill-white text-sm"
          ref={textRef}
          x={xTip - 8}
          y={54 + yRow}
        >
          {situation.title}
        </text>
      </g>
    </g>
  );
}

function Scale({
  end,
  fraction,
  start,
  unit,
  width,
}: {
  end: number;
  fraction: number;
  start: number;
  unit: Unit;
  width: number;
}) {
  const sDate = new Date(start);
  const sYear = sDate.getFullYear();
  const sMonth = sDate.getMonth();
  const sDay = sDate.getDate();
  const sHour = Math.abs(sDate.getTimezoneOffset()) / 60;
  const sDiff = getTimeDiff(unit, start, end);

  return (
    <svg
      data-cy="situations-timeline-scale"
      viewBox={`0 0 ${width} 24`}
      width="100%"
    >
      {Array.from({ length: sDiff + 1 }, (_, i) => {
        let iStartDate: Date;
        if (unit === "hour") {
          iStartDate = new Date(sYear, sMonth, sDay + i, 0);
        } else if (unit === "day") {
          iStartDate = new Date(sYear, sMonth + i, 1, sHour);
        } else {
          iStartDate = new Date(sYear + i, 0, 1, sHour);
        }

        let iEndDate;
        if (unit === "hour") {
          iEndDate = new Date(sYear, sMonth, sDay + i + 1, 0);
        } else if (unit === "day") {
          iEndDate = new Date(sYear, sMonth + i + 1, 1, sHour);
        } else {
          iEndDate = new Date(sYear + i + 1, 0, 1, sHour);
        }
        const iStart = Math.max(0, (iStartDate.getTime() - start) / fraction);
        const iEnd = Math.min(width, (iEndDate.getTime() - start) / fraction);
        const iWidth = Math.max(0, iEnd - iStart - (i === sDiff ? 0 : 2));

        return (
          <React.Fragment key={i}>
            <rect
              className="animate-[appear_2s] fill-slate-200 transition-[x,width] duration-300"
              height="24"
              rx="8"
              width={iWidth}
              x={iStart}
              y="0"
            />
            <text
              className="animate-[appear_2s] fill-slate-500 text-sm"
              key={iStart}
              x={10 + iStart}
              y="16.5"
            >
              {unit === "hour" && (
                <>
                  {iWidth > 100 && (
                    <tspan className="font-bold">
                      {iStartDate.toLocaleDateString("de", {
                        day: "numeric",
                        month: "long",
                      })}
                    </tspan>
                  )}{" "}
                  {iWidth > 140 && (
                    <tspan>
                      {iStartDate.toLocaleDateString("de", { year: "numeric" })}
                    </tspan>
                  )}
                </>
              )}
              {unit === "day" && (
                <>
                  {iWidth > 80 && (
                    <tspan className="font-bold">
                      {iStartDate.toLocaleDateString("de", { month: "long" })}
                    </tspan>
                  )}{" "}
                  {iWidth > 120 && (
                    <tspan>
                      {iStartDate.toLocaleDateString("de", { year: "numeric" })}
                    </tspan>
                  )}
                </>
              )}
              {unit === "week" && (
                <>
                  {iWidth > 120 && <tspan>Kalenderwochen</tspan>}{" "}
                  {iWidth > 80 && (
                    <tspan className="font-bold">
                      {iStartDate.toLocaleDateString("de", { year: "numeric" })}
                    </tspan>
                  )}
                </>
              )}
            </text>
          </React.Fragment>
        );
      })}
    </svg>
  );
}

const stopWhite = { stopColor: "white", stopOpacity: 0.7 };
const stopTransparent = { stopColor: "white", stopOpacity: 0 };
const prefix = "moco3.situationsTimeline.";

export default function SituationsTimeline(props: {
  situations?: SituationType[];
}) {
  const [activeSituation, setActiveSituation] =
    useState<TimelineSituationFragment>();
  const dateInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerOffset = useElementOffset(containerRef);
  const [unit, setUnit] = useLocalStorageState<Unit>(`${prefix}.unit`, "day");
  const unitCount = Math.round(containerOffset.width / unitWidth);
  const width = unitCount * unitWidth;
  const fraction = inMs[unit] / unitWidth;
  const now = useMemo(() => {
    return new Date().getTime();
  }, []);
  const beforeNow = useMemo(() => {
    return toTime(getISODate()) - inMs[unit];
  }, [unit]);
  const [start, setStart] = useLocalStorageState(`${prefix}.start`, beforeNow);
  const end = useMemo(() => {
    return start + inMs[unit] * unitCount;
  }, [start, unit, unitCount]);

  const situations = useMemo(() => {
    const overlap = (testStart: string, testEnd: string) => {
      const testStartTime = toTime(testStart);
      const testEndTime = toTime(testEnd);
      return testStartTime < end && testEnd && testEndTime > start;
    };
    return (props?.situations ?? [])
      .filter((s) => {
        return (
          overlap(s.affectedTimeIntervalsStart, s.affectedTimeIntervalsEnd) ||
          overlap(s.publicationWindowsStart, s.publicationWindowsEnd)
        );
      })
      .sort((a, b) => {
        return getFirstVisibleFromTime(a) - getFirstVisibleFromTime(b);
      })
      .reverse();
  }, [props, start, end]);

  const height = Math.max(55 + (situations.length * 36 || 0), 320);

  // close active situation when slug changes
  // useEffect(() => setActiveSituation(null), [slug]);

  return (
    <div className="relative" ref={containerRef}>
      <h2 className="mb-3 text-3xl font-bold text-slate-500">Timeline</h2>
      <div className="absolute top-2 right-0 flex space-x-3 rounded-lg border border-slate-200 bg-white p-1 text-xs font-medium text-slate-600">
        <div className="flex">
          <div className="mr-1 h-4 w-4 rounded-md bg-orange-300" /> Dauer der
          Einschränkung
        </div>
        <div className="flex pr-1">
          <div className="bg-blue mr-1 h-4 w-4 rounded-md" /> Anzeige-Zeitraum
        </div>
      </div>
      <div className="flex justify-between rounded-t-lg bg-slate-200 p-2">
        <div className="flex space-x-2">
          <UnitButton
            isActive={unit === "hour"}
            onClick={() => {
              return setUnit("hour");
            }}
          >
            Stunden
          </UnitButton>
          <UnitButton
            isActive={unit === "day"}
            onClick={() => {
              return setUnit("day");
            }}
          >
            Tage
          </UnitButton>
          <UnitButton
            isActive={unit === "week"}
            onClick={() => {
              return setUnit("week");
            }}
          >
            Wochen
          </UnitButton>
        </div>
        <div className="flex space-x-2">
          <ToolButton
            onClick={() => {
              return setStart(start - inMs[unit] * Math.round(unitCount / 3));
            }}
          >
            <ChevronLeftIcon className="w-5" />
          </ToolButton>
          <ToolButton
            onClick={() => {
              return setStart(start + inMs[unit] * Math.round(unitCount / 3));
            }}
          >
            <ChevronRightIcon className="w-5" />
          </ToolButton>
          <ToolButton
            className="px-3"
            onClick={() => {
              return setStart(beforeNow);
            }}
          >
            {unit === "hour" ? "Jetzt" : "Heute"}
          </ToolButton>
          <ToolButton
            onClick={() => {
              return dateInputRef.current?.showPicker();
            }}
          >
            <CalendarIcon className="w-5" />
            <input
              className="h-0 w-0 border-none p-0"
              onChange={(e) => {
                return setStart(
                  e.target.value
                    ? toTime(e.target.value) - inMs[unit]
                    : beforeNow,
                );
              }}
              ref={dateInputRef}
              type={unit === "hour" ? "datetime-local" : "date"}
              value={
                unit === "hour"
                  ? getISODateTime(start + inMs[unit])
                  : getISODate(start + inMs[unit])
              }
            />
          </ToolButton>
        </div>
      </div>

      {activeSituation && (
        <>
          <div className="absolute top-[100px] right-[1px] bottom-[33px] w-[300px] animate-[appear_1s] rounded-br-lg bg-white p-4 text-slate-500">
            <ToolButton
              className="absolute right-4 hover:bg-slate-200"
              onClick={() => {
                return setActiveSituation(undefined);
              }}
            >
              <XMarkIcon className="w-5" />
            </ToolButton>
            <h3 className="text-blue mt-5 text-xs font-medium uppercase">
              {"TODO" /*activeSituation.category*/}
            </h3>
            <div className="mt-1 font-semibold">{activeSituation.title}</div>
            <dl className="mt-6 text-sm">
              <dt className="font-medium text-slate-400">Beginn</dt>
              <dd className="-mt-5 ml-16 font-semibold">
                {getDateString(activeSituation.affectedTimeIntervalsStart) ??
                  "-"}
              </dd>
              <dt className="mt-2 font-medium text-slate-400">Ende</dt>
              <dd className="-mt-5 ml-16 font-semibold">
                {getDateString(activeSituation.affectedTimeIntervalsEnd) ?? "-"}
              </dd>
            </dl>
            <dl className="mt-6 text-sm">
              <dt className="font-medium text-slate-400">Start</dt>
              <dd className="-mt-5 ml-16 truncate font-semibold">
                {activeSituation.affectedStopNames?.[0] ?? "-"}
              </dd>
              <dt className="mt-2 font-medium text-slate-400">Ende</dt>
              <dd className="-mt-5 ml-16 truncate font-semibold">
                {activeSituation.affectedStopNames?.[
                  activeSituation.affectedStopNames.length - 1
                ] ?? "-"}
              </dd>
            </dl>
            <Link
              className="text-blue absolute right-4 bottom-4 left-4 rounded-xs bg-slate-100 p-2 text-center text-sm font-bold hover:bg-slate-200"
              href={`/notification/${activeSituation.id}`}
            >
              <PencilSquareIcon className="-mt-1 mr-1 inline-block w-5" />{" "}
              Meldung bearbeiten
            </Link>
          </div>
          <div className="pointer-events-none absolute top-[100px] right-[300px] bottom-[33px] w-[30px] bg-linear-to-l from-slate-500 opacity-20" />
        </>
      )}

      <svg
        className="mb-2 rounded-b-lg border-x border-b border-slate-200"
        viewBox={`0 0 ${width} ${height}`}
        width="100%"
      >
        <defs>
          <linearGradient id="left-fade-in" x1="0%" x2="100%" y1="0%" y2="0%">
            <stop offset="0%" style={stopWhite} />
            <stop offset="100%" style={stopTransparent} />
          </linearGradient>
          <linearGradient id="right-fade-in" x1="0%" x2="100%" y1="0%" y2="0%">
            <stop offset="0%" style={stopTransparent} />
            <stop offset="100%" style={stopWhite} />
          </linearGradient>
        </defs>
        {Array.from({ length: Math.ceil(width / (inMs[unit] / fraction)) })
          .map((_, i) => {
            const iStart = start + i * inMs[unit];
            const date = new Date(iStart);
            const key = date.getTime();
            const x = (i * inMs[unit]) / fraction;
            const isNow = now < iStart + inMs[unit] && now >= iStart;
            let isBlue = false;
            if (unit == "hour") {
              isBlue = date.getHours() > 22 || date.getHours() < 6;
            } else if (unit == "day") {
              isBlue = date.getDay() === 6 || date.getDay() === 0;
            } else if (unit == "week") {
              isBlue = i % 2 === 1;
            }
            let fill = "fill-white stroke-slate-100";
            let sort = 0;
            if (isNow) {
              fill = "fill-green-100 stroke-green-200";
              sort = 2;
            } else if (isBlue) {
              fill = "fill-blue-50 stroke-blue-100";
              sort = 1;
            }
            const textFill = isNow ? "fill-green-500" : "fill-slate-400";

            return {
              fragment: (
                <React.Fragment key={key}>
                  <rect
                    className={`${fill} animate-[appear_1s] stroke-1 transition-all duration-300`}
                    data-cy="situations-timeline-column"
                    height={height}
                    width={unitWidth}
                    x={x}
                    y="0"
                  />
                  <text
                    className={`${textFill} animate-[appear_2s] text-xs`}
                    key={x}
                    textAnchor="middle"
                    x={x + unitWidth / 2}
                    y={height - 28}
                  >
                    {unit === "hour" && (
                      <tspan className="font-bold" dy="1em">
                        {date
                          .toLocaleTimeString("de", { hour: "2-digit" })
                          .substring(0, 2)}
                      </tspan>
                    )}
                    {unit === "day" && (
                      <>
                        <tspan>
                          {date.toLocaleDateString("de", { weekday: "short" })}
                        </tspan>
                        <tspan
                          className="font-bold"
                          dy="1.3em"
                          x={x + unitWidth / 2}
                        >
                          {date.toLocaleDateString("de", { day: "numeric" })}
                        </tspan>
                      </>
                    )}
                    {unit === "week" && (
                      <tspan className="font-bold" dy="1em">
                        {getCalendarWeek(date)}
                      </tspan>
                    )}
                  </text>
                </React.Fragment>
              ),
              sort,
            };
          })
          .sort((a, b) => {
            return a.sort - b.sort;
          })
          .map((i) => {
            return i.fragment;
          })}
        {situations?.map((s, i) => {
          return (
            <Bar
              fraction={fraction}
              key={s.id}
              onClickSituation={(situation) => {
                setActiveSituation(situation);
              }}
              row={situations.length - 1 - i}
              situation={s}
              start={start}
              width={width}
            />
          );
        })}
        <rect // left fade-in overlay (see linear gradient defs)
          className="pointer-events-none"
          fill="url(#left-fade-in)"
          height={height}
          width={unitWidth}
          x="0"
          y="0"
        />
        <rect // right fade-in overlay (see linear gradient defs)
          className="pointer-events-none"
          fill="url(#right-fade-in)"
          height={height}
          width={unitWidth}
          x={width - unitWidth}
          y="0"
        />
      </svg>
      <Scale
        end={end}
        fraction={fraction}
        start={start}
        unit={unit}
        width={width}
      />
    </div>
  );
}

SituationsTimeline.fragment = gql`
  fragment TimelineSituation on SituationType {
    id
    title
    affectedStopNames
    affectedLineNames
    affectedTimeIntervalsStart
    affectedTimeIntervalsEnd
    publicationWindowsStart
    publicationWindowsEnd
    affectedTimeIntervals {
      id
      startTime
      endTime
    }
    publicationWindows {
      id
      startTime
      endTime
    }
  }
`;
