import { useEffect, useState } from "react";

export default function useElementOffset(
  ref: React.RefObject<HTMLDivElement | null>,
) {
  const [offset, setOffset] = useState({ left: 0, width: 0 });

  useEffect(() => {
    const handleResize = () => {
      return (
        ref.current &&
        setOffset({
          left: ref.current.offsetLeft,
          width: ref.current.offsetWidth,
        })
      );
    };
    if (ref.current) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      return window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return offset;
}
