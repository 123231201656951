import { gql } from "graphql-request";
import { useMemo } from "react";
import useSWR from "swr";

import Layout from "@/components/Layout";
import SituationsTimeline from "@/components/SituationsTimeline";
import useConfig from "@/hooks/useConfig";

import type { DashboardQuery, DashboardQueryVariables } from "@/lib/graphql";

const queryDashboard = gql`
  query dashboard($tenant: String!) {
    paginatedSituations(tenant: $tenant, filters: { isEdited: true }) {
      results {
        ...TimelineSituation
      }
    }
  }
  ${SituationsTimeline.fragment}
`;

export default function Home() {
  const { slug } = useConfig();
  const dashboardQueryVariables = useMemo<
    DashboardQueryVariables | undefined
  >(() => {
    if (!slug) {
      return;
    }
    return { tenant: slug };
  }, [slug]);

  const { data } = useSWR<DashboardQuery>(
    [queryDashboard, dashboardQueryVariables],
    null,
    {
      refreshInterval: 60000, // 1 minute
    },
  );

  return (
    <Layout title="Dashboard">
      <SituationsTimeline situations={data?.paginatedSituations?.results} />
      {/* <NotificationsMap /> */}
    </Layout>
  );
}
